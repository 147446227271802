<template>
  <a-modal :maskClosable="false" ref="modalOtp" :width="(methodAuth == OtpConstant.OTP_METHOD_GOOGLE && localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER) ? 650 : 520" class="p-0 modal-register" v-model="visibleModal" @cancel="closeModal" :closable="false" :footer="null">
    <p class="register-title mb-0">{{ $t(OtpConstant.TITLE_BY_TYPE[localTypeAuth]) }}
      <span v-if="localTypeAuth != OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER" style="text-transform: uppercase;">{{ $t(OtpConstant.METHOD_NAME_BY_KEY[methodAuth]) }}</span>
    </p>
    <hr>
    <div class="p-3 register-body form-otp__body" style="width: 100%">
      <div class="popup-otp__main" v-if="localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_TRANSACTION || localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER">
        <div class="progress-main mb-3">
          <div class="step-box">
            <div class="step active">1</div>
            <div class="step-name">Xác thực</div>
          </div>
          <div class="step-box">
            <div :class="verifyBeforeRegister ? 'step active' : 'step'">2</div>
            <div class="step-name">Đăng ký</div>
          </div>
        </div>
        <div class="text-center mb-3 sub-title__otp"> {{ $t('opt.method') }}  <span class="text-danger">*</span></div>
        <div v-if="!selectedItem">
          <a-input-group compact style="width: 100%" v-if="isShowOptionMore && localTypeAuth != OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER">
            <a-select 
              :placeholder="$t('Security.TitleDefault')" 
              :size="'large'" 
              :style="selectedItem == OtpConstant.OTP_METHOD_GOOGLE || !selectedItem ? 'width: 100%' : 'width: 70%;'" 
              @change="handleMethodChange"
              >
              <a-select-option class="slb-option" :value="item.method" v-for="(item, index) in methodOtp" :key="index">{{item.titleInput}}</a-select-option>
              <div slot="dropdownRender" slot-scope="menu">
                <v-nodes :vnodes="menu" />
                <a-divider style="margin: 0;" />
                <div class="option-more"
                    @mousedown="e => e.preventDefault()"
                    @click="redirectSecurity"
                >
                {{$t('opt.addMethod')}}
                </div>
              </div>
            </a-select>
            <a-button class="fw-bold" @click="getOtp(selectedItem)" type="primary" :disabled="disableBtnByMethod[localTypeAuth][selectedItem]" v-if="(selectedItem == OtpConstant.OTP_METHOD_EMAIL || selectedItem == OtpConstant.OTP_METHOD_SMS)" style="width: 30%;height: 40px;">{{ $t('Security.GetOTP') }}
              <span v-if="checkClick[localTypeAuth][selectedItem] && timeOutOTP[localTypeAuth][selectedItem] < 60">&nbsp;({{timeOutOTP[localTypeAuth][selectedItem]}}s)</span>
            </a-button>
          </a-input-group>
          <a-input-group v-else>
            <a-select 
              :size="'large'" 
              :style="selectedItem == OtpConstant.OTP_METHOD_GOOGLE || !selectedItem ? 'width: 100%' : 'width: 70%'" 
              @change="handleMethodChange"
              :placeholder="$t('Security.TitleDefault')"
              >
              <a-select-option class="slb-option" :value="item.method" v-for="(item, index) in methodOtp" :key="index">{{item.titleInput}}</a-select-option>
            </a-select>
            <a-button class="fw-bold" @click="getOtp(selectedItem)" type="primary" :disabled="disableBtnByMethod[localTypeAuth][selectedItem]" v-if="(selectedItem == OtpConstant.OTP_METHOD_EMAIL || selectedItem == OtpConstant.OTP_METHOD_SMS)" style="width: 30%;height: 40px;">{{ $t('Security.GetOTP') }}
              <span v-if="checkClick[localTypeAuth][selectedItem] && timeOutOTP[localTypeAuth][selectedItem] < 60">&nbsp;({{timeOutOTP[localTypeAuth][selectedItem]}}s)</span>
            </a-button>
          </a-input-group>
        </div>
        <div v-else>
          <a-input-group compact style="width: 100%" v-if="isShowOptionMore && localTypeAuth != OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER">
            <a-select 
              :placeholder="$t('Security.TitleDefault')" 
              :size="'large'" 
              :style="selectedItem == OtpConstant.OTP_METHOD_GOOGLE || !selectedItem ? 'width: 100%' : 'width: 70%;'" 
              @change="handleMethodChange" 
              v-model="selectedItem">
              <a-select-option class="slb-option" :value="item.method" v-for="(item, index) in methodOtp" :key="index">{{item.titleInput}}</a-select-option>
              <div slot="dropdownRender" slot-scope="menu">
                <v-nodes :vnodes="menu" />
                <a-divider style="margin: 0;" />
                <div class="option-more"
                    @mousedown="e => e.preventDefault()"
                    @click="redirectSecurity"
                >
                {{$t('opt.addMethod')}}
                </div>
              </div>
            </a-select>
            <a-button class="fw-bold" @click="getOtp(selectedItem)" type="primary" :disabled="disableBtnByMethod[localTypeAuth][selectedItem]" v-if="(selectedItem == OtpConstant.OTP_METHOD_EMAIL || selectedItem == OtpConstant.OTP_METHOD_SMS)" style="width: 30%;height: 40px;">{{ $t('Security.GetOTP') }}
              <span v-if="checkClick[localTypeAuth][selectedItem] && timeOutOTP[localTypeAuth][selectedItem] < 60">&nbsp;({{timeOutOTP[localTypeAuth][selectedItem]}}s)</span>
            </a-button>
          </a-input-group>
          <a-input-group v-else>
            <a-select 
              :size="'large'" 
              :style="selectedItem == OtpConstant.OTP_METHOD_GOOGLE || !selectedItem ? 'width: 100%' : 'width: 70%'" 
              @change="handleMethodChange" v-model="selectedItem" 
              :placeholder="$t('Security.TitleDefault')"
              :default-value="selectedDefault">
              <a-select-option class="slb-option" :value="item.method" v-for="(item, index) in methodOtp" :key="index">{{item.titleInput}}</a-select-option>
            </a-select>
            <a-button class="fw-bold" @click="getOtp(selectedItem)" type="primary" :disabled="disableBtnByMethod[localTypeAuth][selectedItem]" v-if="(selectedItem == OtpConstant.OTP_METHOD_EMAIL || selectedItem == OtpConstant.OTP_METHOD_SMS)" style="width: 30%;height: 40px;">{{ $t('Security.GetOTP') }}
              <span v-if="checkClick[localTypeAuth][selectedItem] && timeOutOTP[localTypeAuth][selectedItem] < 60">&nbsp;({{timeOutOTP[localTypeAuth][selectedItem]}}s)</span>
            </a-button>
          </a-input-group>
        </div>
      </div>
      <div class="popup-otp__main" v-else-if="localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER && verifyBeforeRegister && methodAuth == OtpConstant.OTP_METHOD_EMAIL">
        <div class="progress-main mb-3">
          <div class="step-box">
            <div class="step active">1</div>
            <div class="step-name">Xác thực</div>
          </div>
          <div class="step-box">
            <div :class="verifyBeforeRegister ? 'step active' : 'step'">2</div>
            <div class="step-name">Đăng ký</div>
          </div>
        </div>
        <div class="text-center mb-3 sub-title__otp"> {{ $t('opt.method') }}  <span class="text-danger">*</span></div>
        <a-input-group compact style="width: 100%">
          <a-input :size="'large'" style="width: 70%;" ref="emailInput" :max-length="255" v-model.trim="authEmail" :placeholder="$t('Security.PlaceHolderMail')" :disabled="disableBtnByMethod[localTypeAuth][methodAuth]"></a-input>
          <a-button class="fw-bold" @click="getOtp(methodAuth)" type="primary" :disabled="disableBtnByMethod[localTypeAuth][methodAuth]" style="width: 30%;height: 40px;">{{ $t('Security.GetOTP') }}
            <span v-if="checkClick[localTypeAuth][methodAuth] && timeOutOTP[localTypeAuth][methodAuth] < 60">&nbsp;({{timeOutOTP[localTypeAuth][methodAuth]}}s)</span>
          </a-button>
        </a-input-group>
      </div>

      <div class="popup-otp__main" v-else-if="localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER && verifyBeforeRegister && methodAuth == OtpConstant.OTP_METHOD_SMS">
        <div class="progress-main mb-3">
          <div class="step-box">
            <div class="step active">1</div>
            <div class="step-name">Xác thực</div>
          </div>
          <div class="step-box">
            <div :class="verifyBeforeRegister ? 'step active' : 'step'">2</div>
            <div class="step-name">Đăng ký</div>
          </div>
        </div>
        <div class="text-center mb-3 sub-title__otp"> {{ $t('Security.PhoneNumber') }}  <span class="text-danger">*</span></div>
        <a-input-group compact style="width: 100%">
          <a-input 
            ref="smsInput"
            :size="'large'" 
            style="width: 70%;" 
            :max-length="12" 
            v-model.trim="authSms" 
            :placeholder="$t('Security.PlaceHolderPhone')" 
            @keypress="formatterPhone($event)"
            :disabled="disableBtnByMethod[localTypeAuth][methodAuth]"></a-input>
          <a-button class="fw-bold" @click="getOtp(methodAuth)" type="primary" :disabled="disableBtnByMethod[localTypeAuth][methodAuth]" style="width: 30%;height: 40px;">{{ $t('Security.GetOTP') }}
            <span v-if="checkClick[localTypeAuth][methodAuth] && timeOutOTP[localTypeAuth][methodAuth] < 60">&nbsp;({{timeOutOTP[localTypeAuth][methodAuth]}}s)</span>
          </a-button>
        </a-input-group>
      </div>

      <div class="popup-otp__main" v-else-if="localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER && verifyBeforeRegister && methodAuth == OtpConstant.OTP_METHOD_GOOGLE">
        <div class="progress-main mb-3">
          <div class="step-box">
            <div class="step active">1</div>
            <div class="step-name">Xác thực</div>
          </div>
          <div class="step-box">
            <div :class="verifyBeforeRegister ? 'step active' : 'step'">2</div>
            <div class="step-name">Đăng ký</div>
          </div>
        </div>
        <div class="text-center mb-3 sub-title__2fa" v-html="$t('Security.GoogleAuthenticatorDescriptionModal')"></div>
        <div class="text-center">
          <img :src="google2FaInfo.url" alt="" width="150px" height="150px">
        </div>
        <div class="text-center mb-3">
          <p style="color: #262626;">{{ $t('Security.GoogleAuthenticatorKeyTitle') }}</p>
          <div class="d-flex justify-content-center align-items-center">
            <p class="key mb-0 fw-bold me-3 key-google2fa" @click="copyUrl">{{ google2FaInfo.key }}</p>
            <div class="d-flex align-items-center">
              <a-tooltip slot="addonAfter" :title="$t('Button.Copy')">
                <a-icon type="copy" @click="copyUrl" style="color:#3E97FF;"/>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>

      <div class="popup-otp__main" v-else-if="localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE && methodAuth == OtpConstant.OTP_METHOD_GOOGLE">
        <div class="text-center mb-3 sub-title__2fa" v-html="$t('Security.Google2FaCancel')"></div>
      </div>

      <div class="popup-otp__main" v-else-if="localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE && methodAuth == OtpConstant.OTP_METHOD_EMAIL">
        <div class="text-center mb-3 sub-title__2fa sub-title__sms" v-if="this.isset(authEmail)">{{ $t('Security.CancelTitle') }} email <b style="color: #262626;">{{ maskEmail(authEmail) }}</b> {{ $t('Security.ToUnsubscribe') }}</div>
        <div class="text-center">
          <a-button class="btn-get-otp-to-cancel" @click="getOtp(methodAuth)" :disabled="disableBtnByMethod[localTypeAuth][methodAuth]">
            {{ $t('Security.GetOTP') }} &nbsp; <span v-if="checkClick[localTypeAuth][methodAuth] && timeOutOTP[localTypeAuth][methodAuth] < 60">({{timeOutOTP[localTypeAuth][methodAuth]}}s)</span>
          </a-button>
        </div>
      </div>

      <div class="popup-otp__main" v-else-if="localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE && methodAuth == OtpConstant.OTP_METHOD_SMS">
        <div class="text-center mb-3 sub-title__2fa sub-title__sms" v-if="this.isset(authSms)">{{ $t('Security.CancelTitle') }} {{ $t('Security.PhoneNumberNormal') }} <b style="color: #262626;">{{ '*******' + authSms.slice(-3) }}</b> {{ $t('Security.ToUnsubscribe') }}</div>
        <div class="text-center">
          <a-button class="btn-get-otp-to-cancel" @click="getOtp(methodAuth)" :disabled="disableBtnByMethod[localTypeAuth][methodAuth]">
            {{ $t('Security.GetOTP') }} &nbsp; <span v-if="checkClick[localTypeAuth][methodAuth] && timeOutOTP[localTypeAuth][methodAuth] < 60">({{timeOutOTP[localTypeAuth][methodAuth]}}s)</span>
          </a-button>
        </div>
      </div>
      <div class="text-center mt-3 mb-3 sub-title__otp"> {{ $t('opt.authenticationCode') }}  <span class="text-danger">*</span></div>
      <div class="form-otp d-flex justify-content-center ">
        <div class="ap-otp-inputs d-flex justify-content-center align-items-center">
          <a-input
              v-for="(digit, index) in otpLength"
              :key="index"
              type="tel"
              class="ap-otp-input"
              :max-length="1"
              v-model="otp[index]"
              @input="onInput(index, $event.target.value)"
              @keydown="onKeyDown(index, $event)"
              @paste="onPaste(index, $event)"
              @keypress="formatterPhone($event)"
              @click="handleClick($event)"
              ref="otpInputs"
            />
        </div>
      </div>
      <Recaptcha v-if="isEnableRecaptcha" :language="lang" ref="recaptcha" @verify="tokenRecaptcha"></Recaptcha>
    </div>
    <div class="text-center d-flex justify-content-center pb-2">
      <a-button v-if="(localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE || localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER)" @click="confirmOtp(localTypeAuth)" :disabled="isSubmitVisible" type="danger" :class="localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER ? 'btn-confirm__otp fw-bold' : 'btn-danger__otp fw-bold'" style="margin-right: 15px">
        <span v-if="localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE">{{$t('Security.Cancel')}}</span>
        <span v-else>{{$t('Security.Verify')}}</span>
      </a-button>
      <a-button v-else @click="confirmOtp(localTypeAuth)" :disabled="isSubmitVisible" type="primary" class="btn-confirm__otp fw-bold" style="margin-right: 15px">
        <span v-if="methodAuth == OtpConstant.OTP_METHOD_GOOGLE || localTypeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER">{{$t('Security.Register')}}</span>
        <span v-else>{{$t('opt.submit')}}</span>
      </a-button>
      <a-button class="btn-cancel__otp color-black" @click="closeModal">{{$t('opt.cancel')}}</a-button>
    </div>
  </a-modal>
</template>

<script>
import CallApi from "@/services/api.service";
import OtpConstant from "@/constants/OtpConstant";
import store from "@/store";
import Recaptcha from "./Recaptcha.vue";
import helper from '../../until/helper';
import { mapGetters } from 'vuex';

export default {
components: {
    Recaptcha,
    VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
    },
},
name: "OtpForm",
  props: {
    visibleModal:{
      type: Boolean,
      default: false
    },
    typeAuth:{
      type: Number,
      default: null
    },
    methodAuth:{
      type: Number,
      default: null
    },
    accounts: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      code: null,
      otpLength: 6, // Length of the OTP
      otp: Array(6).fill(''), // Array to hold OTP values
      disabledSubmit: true,
      selectedItem: null,
      selectedDefault: null,
      authSms: null,
      authEmail: null,
      method: null,
      localRequestId: {
        [OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER]: {
          1: null,
          2: null
        },
        [OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE]: {
          1: null,
          2: null
        },
        [OtpConstant.OTP_TYPE_VERIFY_TRANSACTION]: {
          1: null,
          2: null
        },
        [OtpConstant.OTP_TYPE_VERIFY_REGISTER]: {
          1: null,
          2: null
        }
      },
      localRequestIdStorage: {
        [OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER]: {
          1: 'cancelLoginRequestSms',
          2: 'cancelLoginRequestEmail',
        },
        [OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE]: {
          1: 'unsubscribeRequestSms',
          2: 'unsubscribeRequestEmail',
        },
        [OtpConstant.OTP_TYPE_VERIFY_TRANSACTION]: {
          1: 'verifyTransactionRequestSms',
          2: 'verifyTransactionRequestEmail',
        },
        [OtpConstant.OTP_TYPE_VERIFY_REGISTER]: {
          1: 'verifyRegisterRequestSms',
          2: 'verifyRegisterRequestEmail',
        }
      },
      localVisibleModal: this.visibleModal,
      localAccounts: this.accounts,
      OtpConstant: OtpConstant,
      isSubmitVisible: true,
      timeOutOTP: {
        [OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER]: {
          1: 60,
          2: 60
        },
        [OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE]: {
          1: 60,
          2: 60
        },
        [OtpConstant.OTP_TYPE_VERIFY_TRANSACTION]: {
          1: 60,
          2: 60
        },
        [OtpConstant.OTP_TYPE_VERIFY_REGISTER]: {
          1: 60,
          2: 60
        }
      },
      timeExpired: {
        [OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER]: {
          1: 0,
          2: 0
        },
        [OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE]: {
          1: 0,
          2: 0
        },
        [OtpConstant.OTP_TYPE_VERIFY_TRANSACTION]: {
          1: 0,
          2: 0
        },
        [OtpConstant.OTP_TYPE_VERIFY_REGISTER]: {
          1: 0,
          2: 0
        }
      },
      methodOtp: [
        {
          title: 'SMS',
          titleInput: 'OTP SMS',
          status: 1,
          method: OtpConstant.OTP_METHOD_SMS,
          phone: '',
          description: 'SMSDescription',
        },
        {
          title: 'Email',
          titleInput: 'OTP Email',
          status: 1,
          method: OtpConstant.OTP_METHOD_EMAIL,
          email: '',
          description: 'EmailDescription',
        },
        {
          title: 'GoogleAuthenticator',
          titleInput: 'Google Authentication',
          status: 1,
          method: OtpConstant.OTP_METHOD_GOOGLE,
          description: 'GoogleAuthenticatorDescription',
        }
      ],
      methodOtpLoaded: [],
      receivedData: null,
      auth:{},
      isShowOptionMore: true,
      disableBtnByMethod: {
        [OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER]: {
          1: false,
          2: false,
          3: false
        },
        [OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE]: {
          1: false,
          2: false,
          3: false
        },
        [OtpConstant.OTP_TYPE_VERIFY_TRANSACTION]: {
          1: false,
          2: false,
          3: false
        },
        [OtpConstant.OTP_TYPE_VERIFY_REGISTER]: {
          1: false,
          2: false,
          3: false
        }
      },
      timerIdByMethod: {
        [OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER]: {
          1: null,
          2: null,
        },
        [OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE]: {
          1: null,
          2: null,
        },
        [OtpConstant.OTP_TYPE_VERIFY_TRANSACTION]: {
          1: null,
          2: null,
        },
        [OtpConstant.OTP_TYPE_VERIFY_REGISTER]: {
          1: null,
          2: null,
        }
      },
      endTimes: {
        [OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER]: {
          1: 'cancelLoginSms',
          2: 'cancelLoginEmail',
        },
        [OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE]: {
          1: 'unsubscribeSms',
          2: 'unsubscribeEmail',
        },
        [OtpConstant.OTP_TYPE_VERIFY_TRANSACTION]: {
          1: 'verifyTransactionSms',
          2: 'verifyTransactionEmail',
        },
        [OtpConstant.OTP_TYPE_VERIFY_REGISTER]: {
          1: 'verifyRegisterSms',
          2: 'verifyRegisterEmail',
        }
      },
      timerId: null,
      checkClick : {
        [OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER]: {
          1: false,
          2: false,
          3: false
        },
        [OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE]: {
          1: false,
          2: false,
          3: false
        },
        [OtpConstant.OTP_TYPE_VERIFY_TRANSACTION]: {
          1: false,
          2: false,
          3: false
        },
        [OtpConstant.OTP_TYPE_VERIFY_REGISTER]: {
          1: false,
          2: false,
          3: false
        }
      },
      google2FaInfo: {
        url: '',
        key: ''
      },
      isEnableAuth: false,
      isEnableRecaptcha: false,
      tokenRecaptchaVerify: null,
      verifyBeforeRegister: false,
      localTypeAuth: this.typeAuth
    };
  },
  mounted() {
    if(this.localAccounts.email_enabled) {
      this.authEmail = this.localAccounts.auth_email;
    }

    if(this.localAccounts.sms_enabled) {
      this.authSms = this.localAccounts.auth_sms;
    }
    
    this.transformData(this.localAccounts);
    
    let dataLoaded = {
      'isEnableAuth': this.isEnableAuth,
      'methodOtp': this.methodOtpLoaded,
      'authLoginEnable': this.localAccounts.auth_login_enable
    }

    this.$emit('data-loaded', dataLoaded);

    this.getGoogle2FaInfo();

  },
  computed: {
    // computedTypeAuth: {
    //   get() {
    //     return this.typeAuth;
    //   },
    //   set(value) {
    //     console.log();
        
    //     this.$emit('update:typeAuth', value); // Emit an event to the parent
    //   }
    // },
    ...mapGetters({
      lang: 'authentications/getLang',
    }),
  },
  created() {
    Object.keys(this.timerIdByMethod).forEach(key => {

      this.initializeTimerOtp(OtpConstant.OTP_METHOD_EMAIL, key);
      this.initializeTimerOtp(OtpConstant.OTP_METHOD_SMS, key);

      if (localStorage.getItem(this.localRequestIdStorage[key][OtpConstant.OTP_METHOD_SMS])) {
        this.localRequestId[key][OtpConstant.OTP_METHOD_SMS] = localStorage.getItem(this.localRequestIdStorage[key][OtpConstant.OTP_METHOD_SMS]);
      }

      if (localStorage.getItem(this.localRequestIdStorage[key][OtpConstant.OTP_METHOD_EMAIL])) {
        this.localRequestId[key][OtpConstant.OTP_METHOD_EMAIL] = localStorage.getItem(this.localRequestIdStorage[key][OtpConstant.OTP_METHOD_EMAIL]);
      }
      
    });
  },
  watch: {
    // Watch for changes in the requestId prop to keep the local data in sync
    localRequestId(newVal) {
      this.localRequestId = newVal;
    },
    visibleModal(newVal) {
      this.localVisibleModal = newVal;

      if(newVal) {
        let userLocalStorage = JSON.parse(localStorage.getItem('user'));
        let typeAuthCheck = [
          OtpConstant.OTP_TYPE_VERIFY_REGISTER,
          OtpConstant.OTP_TYPE_VERIFY_TRANSACTION,
          OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER,
        ];

        this.transformData(userLocalStorage.accounts);

        if(typeAuthCheck.includes(this.localTypeAuth)) 
        {
          if (localStorage.getItem(this.endTimes[this.localTypeAuth][this.methodAuth])) {
            this.authEmail = userLocalStorage.accounts.auth_email;
            this.authSms = userLocalStorage.accounts.auth_sms;
          } else {
            this.authEmail = null;
            this.authSms = null;
          }
        } else {
          this.authEmail = userLocalStorage.accounts.auth_email;
          this.authSms = userLocalStorage.accounts.auth_sms;
        }

      }
    },
    localAccounts(newVal) {
      
      this.localAccounts = newVal;

      if(this.localAccounts.email_enabled && this.localAccounts.sms_enabled && this.localAccounts.two_2fa_enable) {
        this.isShowOptionMore = false;
      } else {
        this.isShowOptionMore = true;
      }
      
    },
    methodOtpLoaded(newVal) {
      this.methodOtpLoaded = newVal;
    },
    methodOtp(newVal) {
      this.methodOtp = newVal;
    },
    selectedItem(newVal) {
      this.selectedItem = newVal;
    },
    methodAuth(newVal) {
      this.methodAuth = newVal;
    },
    typeAuth(newVal) {
      this.localTypeAuth = newVal;
    }
  },
  // beforeDestroy() {
  //   clearInterval(this.timerIdByMethod);
  // },
  methods: {
    isset: helper.isset,
    initializeTimerOtp(method, type) {
      if(localStorage.getItem(this.endTimes[type][method])) {
        this.initializeTimer(method, type);
        this.checkClick[type][method] = true;
        this.disableBtnByMethod[type][method] = true;
      }
    },
    notifyParent(dataLoaded) {
      // Gửi thông báo thay đổi lên localStorage
      const event = {
        type: 'componentChange',
        timestamp: new Date().toISOString(),
        data: dataLoaded,
      };
      localStorage.setItem('componentEvent', JSON.stringify(event));
    },
    tokenRecaptcha(response) {
        this.tokenRecaptchaVerify = response;
        this.isSubmitVisible = false;
    },
    formatterPhone($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
        $event.preventDefault();
      }
    },
    getGoogle2FaInfo(){
      this.$store.dispatch("security/getGoogle2FAInfo")
          .then(response => {
            if (response.data){
              this.google2FaInfo.url = response.data.googleUrl;
              this.google2FaInfo.key = response.data.key;
            }
          });
    },
    copyUrl() {
      navigator.clipboard.writeText(this.google2FaInfo.key);
      this.showNotice(
          'success',
          this.$t('Notice.Title.Confirm'),
          this.$t('Notice.Message.CopySuccess')
      );
    },
    validateEmailGetOTP(data){
      let isValid = false;

      if(this.isset(data)) {
        data = data.trim();
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isValid = emailPattern.test(data);
      if (!isValid) {
        this.showNotice(
            'error',
            this.$t('Security.InvalidMail',
            ''
            )
        );
      }

      return isValid;
    },
    validateSmsGetOTP(data) {
      let isValid = false;
      
      if(this.isset(data)) {
        data = data.trim();
      }

      const phonePattern = /^(0|(\+84)|84)[0-9]{8,9}$/;
      isValid = phonePattern.test(data);

      if(this.isset(data) && data.length < 10) {
        isValid = false;
      }
      if (!isValid) {
        this.showNotice(
            'error',
            this.$t('Security.InvalidPhone'),
            '',
        );
      }

      return isValid;
    },
    maskEmail(email) {
      if(email) {
        const [username, domain] = email.split('@');
        const firstChar = username.charAt(0);
        const lastChar = username.charAt(username.length - 1);
        const middleChars = '*'.repeat(username.length - 2);

        return firstChar + middleChars + lastChar + '@' + domain;
      }

      return email;
    },
    redirectSecurity() {
      const routeData = this.$router.resolve({ name: 'AccountSecurity' });
      window.open(routeData.href, '_blank');
      this.closeModal();
    },
    initializeTimer(method, type) {
      this.timeExpired[type][method] = parseInt(localStorage.getItem(this.endTimes[type][method]), 10);
      const currentTime = Math.floor(Date.now() / 1000);
      

      if (this.timeExpired[type][method] && this.timeExpired[type][method] > currentTime) {
        this.timeOutOTP[type][method] = this.timeExpired[type][method] - currentTime;
      } else {
        this.resetTimer(method, type);
      }

      this.countDownTimer(method, type);
    },
    resetTimer(method, type) {
      const expireTime = Math.floor(Date.now() / 1000) + 60;
      localStorage.setItem(this.endTimes[type][method], expireTime);
    },
    countDownTimer (method, type) {
      
      this.timerIdByMethod[type][method] = setTimeout(() => {
        if (this.timeOutOTP[type][method] > 0) {
          this.timeOutOTP[type][method]--;

          this.countDownTimer(method, type);

        } else {
          clearTimeout(this.timerIdByMethod[type][method]);
          this.timeOutOTP[type][method] = 60;
          localStorage.removeItem(this.endTimes[type][method], this.timeOutOTP[type][method]);
          this.disableBtnByMethod[type][method] = false;
          this.checkClick[type][method] = false;
        }
      }, 1000);
    },
    transformData(data) {
      this.methodOtpLoaded = [
        {
          title: 'SMS',
          titleInput: 'OTP SMS',
          status: data.sms_enabled ? 1 : 0,
          method: OtpConstant.OTP_METHOD_SMS,
          phone: data.auth_sms,
          description: 'SMSDescription',
        },
        {
          title: 'Email',
          titleInput: 'OTP Email',
          status: data.email_enabled ? 1 : 0,
          method: OtpConstant.OTP_METHOD_EMAIL,
          email: data.auth_email,
          description: 'EmailDescription',
        },
        {
          title: 'GoogleAuthenticator',
          titleInput: 'Google Authentication',
          status: data.two_2fa_enable ? 1 : 0,
          method: OtpConstant.OTP_METHOD_GOOGLE,
          description: 'GoogleAuthenticatorDescription',
        }
      ];

      this.methodOtpLoaded.sort((a, b) => b.status - a.status);

      // let methodDefault = {
      //    title: 'Default',
      //    titleInput: this.$t('Security.TitleDefault'),
      //    status: 1,
      //    method: OtpConstant.OTP_METHOD_DEFAULT,
      // }

      this.methodOtp = this.methodOtpLoaded.filter(item => item.status !== 0);

      if(this.methodOtp.length > 1 && !this.selectedItem) {
        //  this.methodOtp.unshift(methodDefault);

         this.selectedDefault = null;
         this.selectedItem = null;
      } else {
        if(data.two_2fa_enable) {
          this.selectedItem = OtpConstant.OTP_METHOD_GOOGLE;
          this.selectedDefault = OtpConstant.OTP_METHOD_GOOGLE;
        } else {
          if(data.email_enabled) {
              this.selectedItem = OtpConstant.OTP_METHOD_EMAIL;
              this.selectedDefault = OtpConstant.OTP_METHOD_EMAIL;
          }

          if(data.sms_enabled) {
              this.selectedItem = OtpConstant.OTP_METHOD_SMS;
              this.selectedDefault = OtpConstant.OTP_METHOD_SMS;
          }
        }
      }

      if(data.email_enabled && data.sms_enabled && data.two_2fa_enable) {
        this.isShowOptionMore = false;
      } else {
        this.isShowOptionMore = true;
      }

      if(data.email_enabled || data.sms_enabled || data.two_2fa_enable) {
        this.isEnableAuth = true;
      } else {
        this.isEnableAuth = false;
        this.verifyBeforeRegister = true;
      }
      
    },
    confirmOtp(typeAuth){

      if (this.isSubmitVisible) return;

      this.visible = false;
      this.isSubmitVisible = true;
      
      let params = {},
          url = '';

      let methodOtp = this.selectedItem;

      if (typeAuth !== OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER && typeAuth !== OtpConstant.OTP_TYPE_VERIFY_TRANSACTION) {
        methodOtp = this.methodAuth;
      }

      if (methodOtp === OtpConstant.OTP_METHOD_DEFAULT) {
        this.showNotice(
            'error',
            this.$t('Security.Notify.ChooseMethod'),
            ''
        );

        return;
      }

      switch(methodOtp) {
        case OtpConstant.OTP_METHOD_SMS:
          params.method = methodOtp;
          params.type = typeAuth;
          url = "security/verifyOTP";
          
          break;
        case OtpConstant.OTP_METHOD_EMAIL:
          params.method = methodOtp;
          params.type = typeAuth;
          url = "security/verifyOTP";
          break;
        case OtpConstant.OTP_METHOD_GOOGLE:
          url = "security/enableGoogle2FA";
          params.method = methodOtp;
          params.type = typeAuth;
          if(typeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE) {
            url = "security/disableGoogle2FA";
          }

          if(typeAuth == OtpConstant.OTP_TYPE_VERIFY_TRANSACTION || typeAuth == OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER) {
            url = "security/verifyOTP";
          }
          break;
      }

      if(this.isset(this.localRequestId[typeAuth][methodOtp])) {
        params.request_id = this.localRequestId[typeAuth][methodOtp];
      }

      params.otp = Object.keys(this.otp).map(key => `${this.otp[key]}`).join('') ?? '';

      if(this.isEnableRecaptcha) {
        params.token_recaptcha = this.tokenRecaptchaVerify;
      }

      params.lang = this.lang;
      
      this.handleSignUp(params, url);
      
    },
    handleSignUp(params, url){
      this.$store.dispatch(url, params).then(response => {

        if (response.code === 0){
          
          this.transformData(response.data);
          
          let dataLoaded = {
            'isEnableAuth': this.isEnableAuth,
            'methodOtp': this.methodOtpLoaded,
            'authLoginEnable': response.data.auth_login_enable
          }

          this.stopTimer(params.method, params.type);

          if(params.type == OtpConstant.OTP_TYPE_VERIFY_TRANSACTION) {
            this.$emit('submit', params);
          } else {
            let msgSuccess = this.$t('Security.Notify.VerifySuccess', {'methodName': this.$t(OtpConstant.METHOD_NAME_BY_KEY[params.method])});

            if(params.type == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE) {
              if(params.method == OtpConstant.OTP_METHOD_EMAIL) {
                this.authEmail = null;
              } else if (params.method == OtpConstant.OTP_METHOD_SMS) {
                this.authSms = null;
              }
              
              msgSuccess = this.$t('Security.Notify.CancelSuccess', {'methodName': this.$t(OtpConstant.METHOD_NAME_BY_KEY[params.method])});
            } else if(params.type == OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER) {
              msgSuccess = this.$t('Security.Notify.VerifyLoginSuccess');
            }

            this.localAccounts = response.data;

            let userLocalStorage = JSON.parse(localStorage.getItem('user'));

            userLocalStorage.accounts = this.localAccounts;
            localStorage.setItem('user', JSON.stringify(userLocalStorage));
            store.commit('authentications/CHANGE_ACCOUNT', this.localAccounts);

            this.showNotice(
                'success',
                msgSuccess,
                ''
            );

            this.$emit('data-loaded', dataLoaded);
            this.isEnableRecaptcha = false;
            this.tokenRecaptchaVerify = null;

            let dataLoadedRealtime = {
              'isEnableAuth': this.isEnableAuth,
              'methodOtp': this.methodOtp,
            }
            this.notifyParent(dataLoadedRealtime);
            
          }

          if(params.method == OtpConstant.OTP_METHOD_GOOGLE) {
            this.getGoogle2FaInfo();
          }

          if(params.type == OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER) {
            this.verifyBeforeRegister = true;
            this.localTypeAuth = OtpConstant.OTP_TYPE_VERIFY_REGISTER;
            this.otp = Array(6).fill('');
            
          } else {
            this.closeModal();
          }

        }else{

            if(this.isEnableRecaptcha) {
                this.$refs.recaptcha.reset();
                this.tokenRecaptchaVerify = null;
            }

            if(response.errors == 'EnableRecaptcha') {
                
                this.isEnableRecaptcha = true;
                this.showNotice(
                    'error',
                    this.$t('Security.Notify.InvalidOTP'),
                    ''
                );
            } else {

                if(this.isset(response.errors.token_recaptcha) && response.errors.token_recaptcha[0] != '') {
                    this.showNotice(
                        'error',
                        response.errors.token_recaptcha[0],
                        ''
                    );
                } else if(this.isset(response.errors.request_id) && response.errors.request_id[0] != '') {
                    this.showNotice(
                        'error',
                        response.errors.request_id[0],
                        ''
                    );
                } else if(this.isset(response.errors.otp) && response.errors.otp[0] != '') {
                    this.showNotice(
                        'error',
                        response.errors.otp[0],
                        ''
                    );
                } else {
                    this.showNotice(
                        'error',
                        response.errors,
                        ''
                    );
                }
                
            }
            
        }

        // this.isSubmitVisible = false;
        
      });
    },
    stopTimer(method, type) {

      let methodDisabled = null;
        
      if(type == OtpConstant.OTP_TYPE_VERIFY_TRANSACTION || type == OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER) {
        
        switch(method) {
          case OtpConstant.OTP_METHOD_SMS:
            methodDisabled = OtpConstant.OTP_METHOD_EMAIL;
            this.handleResetTimer(type, method);
            this.handleResetTimer(type, methodDisabled);
            break;
          case OtpConstant.OTP_METHOD_EMAIL:
            methodDisabled = OtpConstant.OTP_METHOD_SMS;

            this.handleResetTimer(type, method);
            this.handleResetTimer(type, methodDisabled);
            break;
          case OtpConstant.OTP_METHOD_GOOGLE:
            this.handleResetTimer(type, OtpConstant.OTP_METHOD_SMS);
            this.handleResetTimer(type, OtpConstant.OTP_METHOD_EMAIL);
            break;
        }
      } else {
        this.handleResetTimer(type, method);
      }
      
    },
    handleResetTimer(type, method) {
      if (this.timerIdByMethod[type][method]) {
        
        clearTimeout(this.timerIdByMethod[type][method]);
        this.timerIdByMethod[type][method] = null;
      }
      localStorage.removeItem(this.endTimes[type][method]);
      localStorage.removeItem(this.localRequestIdStorage[type][method]);
      this.timeOutOTP[type][method] = 60;
      this.disableBtnByMethod[type][method] = false;
      this.checkClick[type][method] = false;
    },
    handleMethodChange(value) {
      this.selectedItem = value;
      this.resetLocalData();
    },

    handleParam(type){
      let params = {};

      params.otp = Object.keys(this.otp).map(key => `${this.otp[key]}`).join('') ?? '';
      params.method = this.localTypeAuth;
      params.type = type;

      return params;
    },
    getOtp(method) {
      let typeGet = this.localTypeAuth;
      
      if (method) {

        let params = {
          'method': method,
          'type': typeGet,
        };

        params.lang = this.lang;

        if (typeGet == OtpConstant.OTP_TYPE_VERIFY_TRANSACTION || typeGet == OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER || typeGet == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE) {

          let userLocalStorage = JSON.parse(localStorage.getItem('user'));

          if (method == OtpConstant.OTP_METHOD_SMS){
            let smsInputVal = userLocalStorage.accounts.auth_sms;
            let validSms = this.validateSmsGetOTP(smsInputVal);

            if (!validSms){
              return;
            }

            params.phone = smsInputVal;
            params.email = '';
          } else {
            let emailInputVal = userLocalStorage.accounts.auth_email;
            let validEmail = this.validateEmailGetOTP(emailInputVal);

            if (!validEmail){
              return;
            }

            params.email = emailInputVal;
            params.phone = '';
          }
        } else {
          if (method == OtpConstant.OTP_METHOD_SMS){
            let smsInputVal = this.$refs.smsInput.value;
            let validSms = this.validateSmsGetOTP(smsInputVal);

            if (!validSms){
              return;
            }

            params.phone = smsInputVal;
            params.email = '';
          } else {
            let emailInputVal = this.$refs.emailInput.value;
            let validEmail = this.validateEmailGetOTP(emailInputVal);

            if (!validEmail){
              return;
            }

            params.email = emailInputVal;
            params.phone = '';
          }
        }

        this.disableBtnByMethod[typeGet][method] = true;

        CallApi.getOpt(params).then((response) => {
          if (response.code == 0) {
            this.checkClick[typeGet][method] = true;

            this.resetTimer(method, typeGet);
            this.countDownTimer(method, typeGet);

            this.localRequestId[typeGet][method] = response.data.request_id;
            localStorage.setItem(this.localRequestIdStorage[typeGet][method], response.data.request_id);

            if(typeGet == OtpConstant.OTP_TYPE_VERIFY_REGISTER && (method == OtpConstant.OTP_METHOD_EMAIL || method == OtpConstant.OTP_METHOD_SMS)) {
              let userLocalStorage = JSON.parse(localStorage.getItem('user'));

              userLocalStorage.accounts.auth_email = response.data.auth_email;
              userLocalStorage.accounts.auth_sms = response.data.auth_sms;

              localStorage.setItem('user', JSON.stringify(userLocalStorage));
            }
            
            let msgSuccess = this.$t('Security.Notify.GetOtpEmailSuccess') + ' ' + this.maskEmail(params.email);

            if(method == OtpConstant.OTP_METHOD_SMS) {
              msgSuccess = this.$t('Security.Notify.GetOtpPhoneSuccess') + ' *******' + params.phone.slice(-3);
            }

            this.showNotice('success', msgSuccess, '');
          } else {

            if(response.code == 422) {
              Object.keys(response.errors).forEach(key => {
                this.showNotice('error', response.errors[key][0], '');
              });
              
            } else {
              this.showNotice('error', response.errors, '');
            }

            this.disableBtnByMethod[typeGet][method] = false;
          }
        });
      }
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    closeModal() {
      this.resetLocalData();
      this.$emit('close-modal');
      this.localVisibleModal = false; // Update the local copy
      this.$emit('update:visibleModal', false); // Emit an event to inform the parent
      this.isEnableRecaptcha = false;
      this.verifyBeforeRegister = false;
      
    },
    resetLocalData() {
      // this.stopTimer();
      this.otp = Array(6).fill('');
      this.isSubmitVisible = true;
      this.isEnableRecaptcha = false;
    },
    onInput(index, value) {
      if (value.length > 0 && index < this.otpLength - 1) {
        // Move to the next input if current input is filled
        this.$refs.otpInputs[index + 1].focus();
      }
      this.otp[index] = value;

      const hasNonEmptyValue = this.otp.some(value => !value);

      this.isSubmitVisible = true;

      if(!hasNonEmptyValue || (this.isEnableRecaptcha && this.isset(this.tokenRecaptchaVerify))) {
        this.isSubmitVisible = false;
      }
      
      
    },
    onKeyDown(index, event) {
      const input = event.target;
      if (event.key === "ArrowLeft" && input.selectionStart === 1) {
        event.preventDefault();
      }

      if (event.key === 'Backspace' && index > 0 && this.otp[index] === '') {
        // Move to the previous input if the current input is empty and backspace is pressed
        this.$refs.otpInputs[index - 1].focus();
      }
    },
    onPaste(index, event) {
      event.preventDefault(); // Prevent the default paste action
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text'); // Get pasted data as plain text

      if (!/^\d+$/.test(pastedData)) {
        return false;
      }

      // Only take digits that fit into the remaining fields
      const otpDigits = pastedData.split('').slice(0, this.otpLength - index);
      
      // Fill the OTP fields starting from the current index
      otpDigits.forEach((digit, i) => {
        if (i < this.otpLength - index) {
          this.$set(this.otp, index + i, digit);
          if (i < otpDigits.length - 1) {
            this.$refs.otpInputs[index + i + 1].focus(); // Move to the next input
          }
        }
      });

      const hasNonEmptyValue = this.otp.some(value => !value);

      this.isSubmitVisible = true;

      if(!hasNonEmptyValue || (this.isEnableRecaptcha && this.isset(this.tokenRecaptchaVerify))) {
        this.isSubmitVisible = false;
      }
    },
    handleClick(e) {
      // Đặt con trỏ về cuối input
      const input = e.target;
      input.selectionStart = input.selectionEnd = input.value.length;
    },
    checkNumberInput(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
    },
  }
}
</script>

<style scoped>
#slb-otp{
  height: 36px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  line-height: 36px;
}
#slb-otp option{
  border-radius: 0;
}
div[aria-hidden="true"] {
  display: none;
}
.btn-get-otp-to-cancel{
  color: #1589F7 !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: 600;
}
.ant-select-selection:active{
  border-color: #000000;
}
.option-more{
  line-height: 36px;
  color: #1589F7;
  padding: 0 12px;
  cursor: pointer;
}
.btn-cancel__otp{
  height: 36px;
  width: 90px;
}
.btn-confirm__otp:hover,
.btn-confirm__otp:hover:disabled,
.btn-confirm__otp:disabled {
  background-color: #40a9ff;
  border-color: #40a9ff;
  color: #FFFFFF;
}
.btn-confirm__otp{
  width: 115px;
  height: 36px;
  background-color: #1589F7;
  border-color: #1589F7;
  color: #FFFFFF;
}
.btn-danger__otp:hover,
.btn-danger__otp:hover:disabled,
.btn-danger__otp:disabled{
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  color: #FFFFFF;
}
.btn-danger__otp{
  width: 120px;
  height: 36px;
  background-color: #DA251D;
  border-color: #DA251D;
  color: #FFFFFF;
}
.key-google2fa{
  font-size: 14px;
  color: #262626;
}
.key-google2fa:hover{
  cursor: pointer;
}
.form-otp__body .ant-input-lg,
.form-otp__body .ant-select-lg {
  font-size: 14px;
}
.form-otp .ap-otp-inputs .ap-otp-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  font-size: 16px;
  transition: all 0.15s ease;
  line-height: 24px;
  color: #262626;
  width: 30px;
  height: 36px;
  border-radius: 5px;
  margin: 0 8px;
  padding: 0;
  line-height: 0;
}
.register-title{
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #262626;
  text-align: center;
  margin-bottom: 24px;
}
.sub-title__otp{
  color: #262626;
  font-weight: 700;
  font-size: 13px;
}
.sub-title__2fa{
  color: #262626;
  font-size: 13px;
}
.register-body{
  padding: 0 20px;
}
hr{
  margin-top: 10px;
  margin-bottom: 0px;
}
#g-recaptcha{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.sub-title__sms {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.popup-otp__main .ant-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.popup-otp__main .ant-btn{
  padding: 0 8px;
}
.progress-main {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  user-select: none;
}
.progress-bar {
  position: absolute;
  width: 0;
  height: 1px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #1589F7;
  transition: width 0.4s ease;
  z-index: 0;
}
.step-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
}
.step-box::before {
  content: "";
  background-color: #1589F7;
  position: absolute;
  width: 50%;
  height: 2px;
  top: calc(50% - 12px);
  right: 0;
  transform: translate(0, -50%);
  margin: auto;
  z-index: 0;
}
.step-box:last-child::before {
  content: "";
  left: 0;
  right: auto;
}
.step-name {
  font-size: 14px;
  font-weight: 600;
}
.step {
  width: 30px;
  height: 30px;
  background-color: #FFF;
  border-radius: 50%;
  border: 2px solid #1589F7;
  display: grid;
  place-items: center;
  color: #1589F7;
  transition: border-color 0.5s ease-in-out;
  position: relative;
  z-index: 1;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 5px;
}
.step.active {
  border-color: #1589F7;
  color: #FFF;
  background-color: #1589F7;
}
</style>