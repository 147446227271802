<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"></PageTitle>

    <section class="section security-section mb-0">
      <a-spin :spinning="loadingData" size="large">
        <a-card class="mb-30 shadow" style="min-height: 70vh;">
        <h1>{{ $t('Security.Title') }}</h1>
        <div class="mb-4">
          <div class="method-otp" v-for="(method, index) in methodOtp" :key="index">
            <a-row>
              <a-col class="mb-3" :xs="{ span: 24 }" :xl="{ span: 12 }">
                <div class="method-title mb-2">
                  <h3>{{ $t('Security.' + method.title) }}</h3>
                  <div v-if="method.status === 0" class="method-status">
                    <p>{{ $t('Security.NotRegistered') }}</p>
                  </div>
                  <div v-else class="method-status status-active">
                    <p>{{ $t('Security.Registered') }}</p>
                  </div>
                </div>
                <p style="font-style: italic;">{{ $t('Security.' + method.description) }}</p>
                <p v-if="method.method == OtpConstant.OTP_METHOD_SMS && method.status == OtpConstant.OTP_ACTIVE">{{ $t('Security.RegisteredPhoneNumber') }}: <span class="fw-bold format-phone">{{ maskPhoneNumber(method.phone) }}</span></p>
                <p v-else-if="method.method == OtpConstant.OTP_METHOD_EMAIL && method.status == OtpConstant.OTP_ACTIVE">{{ $t('Security.RegisteredEmail') }}: <span class="fw-bold">{{ maskEmail(method.email) }}</span></p>
              </a-col>
              <a-col :xs="{ span: 24 }" :xl="{ span: 3 }">
                <div v-if="method.method == OtpConstant.OTP_METHOD_SMS">
                  <a-button v-if="method.status == OtpConstant.OTP_ACTIVE" class="method-button" type="danger" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.CancelBtn') }}</a-button>
                  <a-button v-else class="method-button" type="primary" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.RegisterBtn') }}</a-button>
                </div>
                <div v-else-if="method.method == OtpConstant.OTP_METHOD_EMAIL">
                  <a-button v-if="method.status == OtpConstant.OTP_ACTIVE" class="method-button" type="danger" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.CancelBtn') }}</a-button>
                  <a-button v-else class="method-button" type="primary" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.RegisterBtn') }}</a-button>
                </div>
                <div v-else>
                  <a-button v-if="method.status == OtpConstant.OTP_ACTIVE" class="method-button" type="danger" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.CancelBtn') }}</a-button>
                  <a-button v-else class="method-button" type="primary" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.RegisterBtn') }}</a-button>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- <h1 class="mb-1">{{ $t('Security.LoginSectionTitle') }}</h1>
        <p>{{ $t('Security.LoginSectionDescription') }}</p>
        <div class="toggle-main">
          <toggle-button :value="loginSecurityStatus" color="#82C7EB" :sync="true" @change="changeStatus($event)" :disabled="isEnableAuth"/>
          <div v-if="loginSecurityStatus" class="text-toggle toggle-on">{{ $t('Account.StatusOn') }}</div>
          <div v-else class="text-toggle toggle-off">{{ $t('Account.StatusOff') }}</div>
        </div> -->
        <h1 class="mb-1 mt-3">{{ $t('Security.UserDevice') }}</h1>
        <a-table :columns="columns" :pagination="pagination" :data-source="listDevice" :row-key="record => record.id">
          <span slot="action" ></span>
          <template #sttSlots>
            STT
          </template>
          <template slot="stt" slot-scope="text, record,index">
            {{ index + 1 }}
          </template>
          <template #customTitleNo>
            {{ $t('Security.DeviceName') }}
          </template>
          <template #customTitleName>
            {{ $t('Security.DeviceBrowser') }}
          </template>
          <template #lastLogin>
            {{ $t('Security.LastLogin') }}
          </template>
          <template #customTitleActive>
            {{ $t('Security.Action') }}
          </template>
          <template slot="timeLogin" slot-scope="text, record">
            {{ showFormatDate(record.last_login_at, "HH:mm:ss DD/MM/YYYY") }}
          </template>
          <template slot="action"  slot-scope="text, record">
            <a-button type="danger" @click="showModalDeleteDevice(record.user_id,record.device_id,record.token)">
              {{$t('Security.DeviceDelete')}}
            </a-button>
          </template>
          <template slot="status"  slot-scope="text, record">
            {{record.device_os}}<span v-if="record.status == 1" style="color:green; margin-left: 5px">( {{$t('Security.DeviceStatus')}} )</span>
          </template >
        </a-table>
      </a-card>
      </a-spin>
    </section>
    <a-modal
        :visible="visibleDeleteDevice"
        :closable="false"
        :footer="null"
        @ok="handleOk"
        centered
        @cancel="handleCancel"
    >
      <h3 class="text-center">{{ $t('Security.DeviceDelete') }}</h3>
      <p class="text-center">{{ $t('Security.DeviceDeleteText') }}</p>
      <div class="button-container mt-4 text-center">
        <a-button type="danger" style="margin-right: 10px" @click="handleOk">{{ $t('Security.DeviceDeleteButton') }}
        </a-button>
        <a-button @click="handleCancel">{{ $t('Security.DeviceDeleteCancel') }}</a-button>
      </div>
    </a-modal>
    <OtpForm
        :accounts="user.accounts"
        :requestId="requestIdRegister"
        :visibleModal.sync="visibleModalRegister"
        @close-modal="handleModalRegisterClose"
        :typeAuth="typeAuth"
        :methodAuth="methodAuth"
        @data-loaded="handleDataLoaded">
    </OtpForm>
    <a-modal v-model="visibleModalEnableLogin" footer :closable="false" id="modal-alert" :maskClosable="false">
      <div class="alert-content mb-2">
        <div class="alert-message mb-4">
          <h5 class="mb-4 fw-bold text-center" style="text-transform: uppercase;">{{
              $t('Security.TitleEnableLogin')
            }}</h5>
          <p class="mb-2" style="font-size: 13px;">{{ $t('Security.TextEnableLogin') }}</p>
          <p class="mb-2" style="font-size: 13px;">{{ $t('Security.SubEnableLogin') }}</p>
        </div>
      </div>
      <div class="alert-footer text-center">
        <a-button class="me-3" type="primary" @click="enableAuthLogin">{{ $t('Security.BtnEnableLogin') }}</a-button>
        <a-button @click="closeAlertModal">{{ $t('Balance.ButtonTitle.close') }}</a-button>
      </div>
    </a-modal>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import OtpForm from "@/components/OtpTransaction/OtpForm";
import OtpConstant from "@/constants/OtpConstant";
import store from "@/store";
import {mapActions, mapGetters} from 'vuex';
import CallApi from "@/services/api.service";
import helper from "@/until/helper";
import moment from "moment";
import { getFingerprint,setOption } from '@thumbmarkjs/thumbmarkjs';  // Importing ThumbmarkJS
export default {
  name: 'AccountSecurity',
  components: {
    AppMasterVue,
    PageTitle,
    OtpForm
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
      profile: 'user/getProfile'
    }),
  },
  data() {
    return {
      title: "Sidebar.AccountSecurity",
      OtpConstant: OtpConstant,
      typeAuth: null,
      methodAuth: null,
      visibleModalEnableLogin: false,
      requestIdRegister: null,
      isEnableAuth: false,
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.AccountSecurity",
          routeName: 'AccountSecurity',
          active: true,
        },
      ],
      methodOtp: [],
      loginSecurityStatus: false,
      visibleModalRegister: false,
      dataRegister: {
        method: '',
        status: 0
      },
      loadingData: false,
      isDisableBtnMethod: false,
      listDevice: [],
      columns: [
        {
          slots: {title: "sttSlots"},
          scopedSlots: {customRender: 'stt'},
        },
        {
          slots: {title: "customTitleNo"},
          dataIndex: 'device_os',
          key: 'device_os',
          scopedSlots: {customRender: 'status'},
        },
        {
          slots: {title: "customTitleName"},
          dataIndex: 'device_browser',
          key: 'device_browser',
        },
        {
          slots: {title: "lastLogin"},
          scopedSlots: {customRender: 'timeLogin'},
          dataIndex: 'last_login_at',
          key: 'last_login_at',
        },
        {
          slots: {title: "customTitleActive"},
          scopedSlots: {customRender: 'action'},
        },
      ],
      visibleDeleteDevice: false,
      dataDeleteDevice: {
        user_id: null,
        device_id: null
      },
      pagination: {
        pageSize: 10,
        current: 1,
        onChange: this.handlePageChange,
      },
      fingerprint: null,
      deleteDeviceSuccessTitle: "Security.DeleteDeviceSuccessTitle",
      deleteDeviceSuccess: "Security.DeleteDeviceSuccess"
    }
  },
  mounted() {
    this.generateFingerprint()
    window.addEventListener('load', this.handlePageRefresh);
    document.addEventListener("click", this.handleClickOutside);
    window.addEventListener('storage', this.handleStorageEvent);

  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    isset: helper.isset,
    ...mapActions('authentications', ['logout']),
    handleLogout() {
      this.logout();
    },
    handleStorageEvent(event) {
      if (event.key === 'componentEvent') {
        // this.visibleModalRegister = false;
        location.reload();
      }
    },
    async generateFingerprint() {
      // Lưu fingerprint vào data
      setOption('exclude', ['webgl', 'canvas.commonImageDataHash', 'system.browser.version']);
      const fp = await getFingerprint(setOption);
      this.fingerprint = fp.hash;
      await CallApi.getListDevice({
        "username": this.user.user.username,
        "device_token": this.fingerprint
      }).then((response) => {
        if (response) {
          this.listDevice = response.data;
        }
      });
      console.log('Fingerprint đã tạo:', this.fingerprint);
    },
    handlePageChange(page) {
      this.pagination.current = page;
    },
    showFormatDate(data = null, format = "DD/MM/YYYY HH:mm:ss") {
      if (moment(data).isValid) {
        return moment(data).format(format);
      }
      return moment().format(format);
    },
    handleLoading: function(){
      this.loadingData = !this.loadingData;
    },
    async callApiGetAccount() {
      this.handleLoading();
      await this.$store.dispatch("user/getMe").then(response => {
        if (response) {

          let accountData = response.accounts;
          let userLocalStorage = JSON.parse(localStorage.getItem('user'));

          userLocalStorage.accounts = accountData;
          localStorage.setItem('user', JSON.stringify(userLocalStorage));
          store.commit('authentications/CHANGE_ACCOUNT', accountData);

          this.methodOtp = [
            {
              title: 'SMS',
              titleInput: 'OTP SMS',
              status: accountData.sms_enabled ? 1 : 0,
              method: OtpConstant.OTP_METHOD_SMS,
              phone: accountData.auth_sms,
              description: 'SMSDescription',
            },
            {
              title: 'Email',
              titleInput: 'OTP Email',
              status: accountData.email_enabled ? 1 : 0,
              method: OtpConstant.OTP_METHOD_EMAIL,
              email: accountData.auth_email,
              description: 'EmailDescription',
            },
            {
              title: 'GoogleAuthenticator',
              titleInput: 'Google Authentication',
              status: accountData.two_2fa_enable ? 1 : 0,
              method: OtpConstant.OTP_METHOD_GOOGLE,
              description: 'GoogleAuthenticatorDescription',
            }
          ];

          this.methodOtp.sort((a, b) => b.status - a.status);

          if (accountData.email_enabled || accountData.sms_enabled || accountData.two_2fa_enable) {
            this.isEnableAuth = false;
          } else {
            this.isEnableAuth = true;
          }

          this.loginSecurityStatus = accountData.auth_login_enable;

        }
      });
      this.handleLoading();
    },
    handlePageRefresh() {
      this.callApiGetAccount();
    },
    enableAuthLogin() {
      let params = {};

      CallApi.authLoginEnable(params).then((response) => {
        if (response.code == 0) {
          this.showNotice('success', this.$t('Security.Notify.EnableLoginSuccess'), '');
          this.visibleModalEnableLogin = false;
          this.loginSecurityStatus = true;

          let userLocalStorage = JSON.parse(localStorage.getItem('user'));

          userLocalStorage.accounts = response.data;
          localStorage.setItem('user', JSON.stringify(userLocalStorage));
          store.commit('authentications/CHANGE_ACCOUNT', response.data);

        } else {
          this.showNotice('error', response.errors, '');
        }
      });
    },
    validateEmailGetOTP(data) {
      let isValid = false;

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isValid = emailPattern.test(data);
      if (!isValid) {
        this.showNotice(
            'error',
            this.$t('Security.InvalidMail',
                ''
            )
        );
      }

      return isValid;
    },
    validateSmsGetOTP(data) {
      let isValid = false;

      const phonePattern = /^(0|(\+84)|84)[0-9]{8,9}$/;
      isValid = phonePattern.test(data);
      if (!isValid) {
        this.showNotice(
            'error',
            this.$t('Security.InvalidPhone'),
            '',
        );
      }

      return isValid;
    },
    handleDataLoaded(data) {
      this.loadingData = false;
      this.isEnableAuth = data.isEnableAuth ? false : true; // False is enable (Tức là có 1 trong 3 PTTX được active)
      this.methodOtp = data.methodOtp;
      this.loginSecurityStatus = data.authLoginEnable;
      
    },
    showModalRegister(status, method) {
      this.typeAuth = OtpConstant.OTP_TYPE_VERIFY_REGISTER;
      
      if (status == OtpConstant.OTP_INACTIVE) {
        this.methodAuth = method;

        if (!this.isEnableAuth) {
          this.typeAuth = OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER;
        }
      } else {
        this.typeAuth = OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE;
        this.methodAuth = method;
      }

      this.visibleModalRegister = true;
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    showModalDeleteDevice(user_id, device_id) {
      this.visibleDeleteDevice = true;
      this.dataDeleteDevice = {
        user_id: user_id,
        device_id: device_id,
      }
    },
    handleOk() {
      this.visibleDeleteDevice = false;
      CallApi.logOutDevice(this.dataDeleteDevice).then((response) => {
        if (response) {
          CallApi.getListDevice({
            "username": this.user.user.username,
            "device_token": this.fingerprint
          }).then((response) => {
            if (response) {
              this.listDevice = response.data;
            }
          });
          // this.handleLogout()
          this.showNotice('success', this.$t('Security.DeleteDeviceSuccessTitle'), this.$t('Security.DeleteDeviceSuccess'));
        } else {
          console.log(response)
        }
      });
    },
    handleCancel() {
      this.visibleDeleteDevice = false;
    },

    handleModalRegisterClose() {
      this.visibleModalRegister = false;
    },
    closeAlertModal() {
      this.visibleModalEnableLogin = false;
    },
    changeStatus(event) {
      if (event.value) {
        this.visibleModalEnableLogin = true;
      } else {
        this.typeAuth = OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER;
        this.visibleModalRegister = true;
      }
    },
    maskPhoneNumber(phoneNumber) {
      if (this.isset(phoneNumber)) {
        let start = '*';
        const lastThreeDigits = phoneNumber.slice(-3);
        const maskedPart = start.repeat(phoneNumber.length - 3);

        return maskedPart + lastThreeDigits;
      }

      return '';

    },
    maskEmail(email) {
      if (this.isset(email)) {
        const [localPart, domain] = email.split('@');
        const firstChar = localPart.charAt(0);
        const lastChar = localPart.slice(-1);
        const maskedLocalPart = `${firstChar}*******${lastChar}`;
        return `${maskedLocalPart}@${domain}`;
      }

      return '';

    },
    handleClickOutside(event) {
      // Kiểm tra nếu click nằm ngoài modal
      if (this.$refs.modal && !this.$refs.modal.contains(event.target)) {
        this.closeModal();
      }
    },
  }
}
</script>

<style>
.security-section h1 {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 27px;
  color: #012970;
  font-weight: 600;
}

.security-section p {
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #000000;
  font-style: italic;
}

.method-otp h3 {
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #000000;
  margin-bottom: 0;
}

.method-otp p {
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #000000;
  margin-bottom: 5px;
}

.method-otp .method-title {
  display: flex;
  align-items: center;
}

.method-otp .method-status {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #A0A4A8;
  width: 130px;
  height: 26px;
  margin-left: 20px;
  border-radius: 8px;
}

.method-otp .method-status p {
  font-weight: 600;
  font-size: 13px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.status-active {
  background: #00C851 !important;
}

.method-otp .method-button {
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: #FFFFFF;
  width: 100%;
  max-width: 115px;
  height: 30px;
  line-height: 28px;
  transition: all 0.2s ease;
}

.method-otp .method-button:hover {
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, .5), 0 4px 8px 3px rgba(60, 64, 67, .2) !important;
}

.method-otp .method-button.ant-btn-primary:active,
.method-otp .method-button.ant-btn-primary:disabled,
.method-otp .method-button.ant-btn-primary:focus,
.method-otp .method-button.ant-btn-primary {
  color: #FFFFFF;
  background-color: #1589F7;
  border-color: #1589F7;
  box-shadow: none;
  text-shadow: none;
}

.method-otp .method-button.ant-btn-danger:active,
.method-otp .method-button.ant-btn-danger:disabled,
.method-otp .method-button.ant-btn-danger:focus,
.method-otp .method-button.ant-btn-danger {
  background-color: #DA251D;
  border-color: #DA251D;
  box-shadow: none;
  text-shadow: none;
  color: #FFFFFF;
}

@media (max-width: 1200px) {
  .method-otp .method-title {
    justify-content: space-between;
  }

  .method-otp {
    border-bottom: 1px solid #B9B9B9;
    margin-bottom: 20px;
  }

  .method-otp .ant-row {
    padding-bottom: 20px;
  }
}

.format-phone {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.toggle-main {
  display: flex;
  align-items: center;
}

.text-toggle {
  margin-left: 10px;
}

.toggle-off {
  color: #B9B9B9;
  font-weight: 700;
}

.toggle-on {
  color: #1589F7;
  font-weight: 700;
}
</style>